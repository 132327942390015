import React, { useEffect, useRef, useState } from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';

import withAuth from '../../hoc/withAuth';
import { Navbar } from '../../components';
import UpperLayer from '../../components/upperLayer/UpperLayer';
import { getFormattedCurrentTime } from '../../helpers/generalHelpers';

import {
  reportFromUser,
  reportofselfPayment,
  reportofPayment,
  reportofUsesTxt,
  reportofLeads,
} from '../../actions/user.actions';
import Calendar from './calender';
import Icon from '../../components/icon';
import { ICONS } from '../../constants/icons.constanst';
import { downloadFile } from '../../helpers/generalHelpers';

import style from './Reports.module.scss';
import Button from '../../components/button/Button';
import { isEmpty } from 'lodash';

const Reports = () => {
  const isDataFetched = useRef(false);

  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState(new Date(2024, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState('');

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const handleConsole = async () => {
    const from = startDate.getTime() / 1000;
    const to = endDate.getTime() / 1000;

    if (isEmpty(option)) {
      return alert('אנא סוג דווח');
    }

    setIsLoading(true);

    if (option === 'קובץ לקוחות') {
      const response = await reportFromUser(from, to);

      // Save the file using file-saver
      downloadFile(response, 'קובץ לקוחות');
    } else if (option === 'חשבונית עצמית') {
      const response = await reportofselfPayment(from, to);

      // Save the file using file-saver
      downloadFile(response, 'חשבונית עצמית');
      //self
    } else if (option === 'תשלומים') {
      //paymnry
      const response = await reportofPayment(from, to);

      // Save the file using file-saver
      downloadFile(response, 'תשלומים');
    } else if (option === 'קובץ לקוחות לקליטה') {
      //uses
      const response = await reportofUsesTxt(from, to);

      // Save the file using file-saver
      downloadFile(response, 'קובץ לקוחות לקליטה', 'txt');
    } else if (option === 'ליד - משתמש') {
      //leads
      const response = await reportofLeads(from, to);

      // Save the file using file-saver
      downloadFile(response, 'ליד - משתמש');
    }

    setIsLoading(false);
  };

  const handleDateChange = async (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    (async () => {
      if (isDataFetched.current) return;
      isDataFetched.current = true;
      setDate(getFormattedCurrentTime());
    })();
  }, []);

  return (
    <div className={style.Container}>
      <div style={{ width: '90px' }}>
        <Navbar />
      </div>

      <div className={style.mainPage}>
        <UpperLayer heading="דיווחים" asOfDate="נכון לתאריך" date={date} />

        <div className={style.secondLayer}>
          <FormControl className={style.dropdown}>
            <Select
              value={option}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              IconComponent={() => <Icon type={ICONS.DROPARROW} />}
              style={{ padding: '0 10px' }}
              renderValue={(value) => (value ? value : 'בחר דוח')}
            >
              <MenuItem value="קובץ לקוחות">קובץ לקוחות</MenuItem>
              <MenuItem value="חשבונית עצמית">חשבונית עצמית</MenuItem>
              <MenuItem value="תשלומים">תשלומים</MenuItem>
              <MenuItem value="קובץ לקוחות לקליטה">קובץ לקוחות לקליטה</MenuItem>
              <MenuItem value="ליד - משתמש">ליד - משתמש</MenuItem>
            </Select>
          </FormControl>

          <div
          //  style={{ marginLeft: '26px' }}
          >
            <Calendar startDate={startDate} setStartDate={setStartDate} hadnleSaveClick={handleDateChange} />
          </div>
          <Button loading={isLoading} className={style.button} onClick={handleConsole}>
            הורד
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withAuth(Reports);
