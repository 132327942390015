import { every, isEmpty, last, size, split } from 'lodash';
import { utils, writeFileXLSX } from 'xlsx';

export const downloadExcel = (data, fileName) => {
  const ws = utils.json_to_sheet(data);
  // console.log(ws, 'wssss');

  const wb = utils.book_new();
  // console.log(wb, 'wbbbb');

  utils.book_append_sheet(wb, ws, 'Data');
  // console.log(utils.book_append_sheet(wb, ws, 'Data'));

  writeFileXLSX(wb, `${fileName}.xlsx`);
  // console.log(writeFileXLSX(wb, `${fileName}.xlsx`), 'pppppppp');
};

export const numberWithCommas = (x) => {
  if (x === 0) return 0;
  if (!x) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const removeCommas = (s) => s.toString().replace(/,/g, '');
export const validateDate = (x) => {
  var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

  if (x.match(pattern)) {
    return;
  }

  return 'ENTER IN DD/MM/YYYY FORMAT';
};

export const validateId = (value) => {
  if (size(value) !== 9) {
    return 'המזהה אינו חוקי';
  }
  return '';
};

// this function will validate mobile number
export const validateMobileNumber = (value) => {
  let err = validateNumber(value);
  if (isEmpty(err) && size(value) !== 10) {
    err = 'מספר הטלפון אינו חוקי';
  }
  return err;
};

// this function will validate email
export const validateEmail = (value) => {
  const emailRegex =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value) ? '' : 'האימייל אינו חוקי';
};

// this function validates link and returns true if it is valid
export const validateUrl = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(url) ? '' : 'הזן כתובת אתר חוקית';
};

// this functio will validate empty field
export const validateEmptyField = (field) => (isEmpty(field) ? 'שדה לא יכול להיות ריק' : '');

export const handlebudget = (budget, valuation) => {
  if (isEmpty(validateNumber(budget))) {
    if (parseFloat(valuation) > parseFloat(budget)) {
      return `מינימום, ${valuation}`;
    } else {
      return '';
    }
  } else {
    return validateNumber(budget);
  }
};

// this function will check if errors object is empty or not
export const isEmptyErrors = (errors) => every(errors, (value) => isEmpty(value));

// this function will check if field is number or not
export const validateNumber = (value) => {
  const pattern = /^\d+$/;
  return pattern.test(value) ? '' : 'הכנס מספר';
};

// this function will convert number to two digit
export const convertToTwoDigit = (value) => {
  if (parseInt(value) < 10) {
    return `0${value}`;
  }

  return value;
};

// this fucntion will return the current formatted time
export const getFormattedCurrentTime = () => {
  const today = new Date();
  const date = convertToTwoDigit(today.getDate());
  const month = convertToTwoDigit(parseInt(today.getMonth() + 1));
  const year = today.getFullYear();
  const hours = convertToTwoDigit(today.getHours());
  const minutes = convertToTwoDigit(today.getMinutes());

  return `${date}/${month}/${year} , ${hours}:${minutes}`;
};

export const getFormattedTime = (timestamp) => {
  let seconds = timestamp / 1000;
  const minutes = parseInt(seconds / 60);
  seconds = seconds - minutes * 60;
  return `${convertToTwoDigit(minutes)}:${convertToTwoDigit(parseInt(seconds))}`;
};

export const getFormattedCurrentDate = () => {
  const today = new Date();
  const date = convertToTwoDigit(today.getDate());
  const month = convertToTwoDigit(parseInt(today.getMonth() + 1));
  const year = today.getFullYear();
  return `${date}/${month}/${year}`;
};

// this function will return the image extension
export const getImageExtension = (imageObject) => {
  const imageTyep = imageObject.type;
  const imageArray = split(imageTyep, '/');
  return last(imageArray);
};

export const convertTimestampIntoDate = (timestamp) => {
  if (!timestamp && !(typeof timestamp === 'string' || typeof timestamp === 'number')) return '';
  const time = parseInt(timestamp) * 1000;
  if (time < 0) return '';
  const dateobj = new Date(time);
  const date = convertToTwoDigit(dateobj.getDate());
  const month = convertToTwoDigit(parseInt(dateobj.getMonth() + 1));
  const year = dateobj.getFullYear();
  return `${date}/${month}/${year}`;
};

// combined function for both csv and txt
export const downloadFile = (data, fileName, type = 'csv') => {
  // type = csv or txt
  let typeToUse = type === 'csv' ? 'csv' : 'plain';
  if (data === false) return;

  const normalizedData = data.replace(/\r?\n/g, '\r\n').trim();

  const utf8Bom = '\uFEFF';

  const blob = new Blob([utf8Bom + normalizedData], { type: `text/${typeToUse};charset=utf-8;` });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  // a.download = 'report.csv'; // The name of the file
  a.download = `${fileName}.${type}`; // The name of the file

  // Append anchor to the document body
  document.body.appendChild(a);

  // Trigger click event on the anchor to start the download
  a.click();

  // Remove anchor from the document body
  document.body.removeChild(a);

  // Revoke the object URL to free up memory
  window.URL.revokeObjectURL(url);
};
